<template>
  <div>
    <v-card flat class="grey lighten-5">
        <v-container class="grey lighten-5 fill-height">
            <v-card :shaped="true" :raised="true" width="100%" class="mb-6">
                <div class="align-center mb-4 pt-3" style="background: #FFFFFF; box-shadow: 0px 3px 12px #0000000F; width: 100%; height: 80px;">
                    <span class="title ml-9" style="color: #F16B32;">{{ data.name }}</span>
                    <div class="ml-9 subtitle-1" style="color:#6A6A6A">{{ data.role_name }}</div>
                    <div class="float-right align-center mr-6" style="margin-top: -50px;">
                        <v-btn depressed rounded class="text-capitalize" color="#00D79E" style="color: #FFFFFF" @click="dialog = true">{{ lang.t('$vuetify.edit_admin') }}</v-btn>
                        <!-- <v-btn class="mx-2" depressed fab dark small color="#FDF1EF" style="border: 1px solid #E74C3C;" @click="delAction">
                            <v-icon color="#E74C3C">mdi-trash-can-outline</v-icon>
                        </v-btn> -->
                        <button-confirm :name="data.name" :message="'$vuetify.del_account'" @confirm="delDataAdmin"></button-confirm>
                    </div>
                </div>
                    <v-row class="mt-4 ml-9">
                        <v-col :md="3">
                            <v-avatar size="176" tile>
                              <v-img :src="data.photo"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col :md="6" class="pl-4" style="margin-left: -40px">
                            <!-- <v-row>
                                <v-col :md="4"> <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.username') }}</span> </v-col>
                                <v-col> <span class="subtitle-1 font-weight-bold">{{ data.username }}</span> </v-col>
                            </v-row> -->
                            <!-- <hr style="border: 1px solid #E0E0E0;margin-top: -10px"> -->
                            <v-row>
                                <v-col :md="4"> <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.email') }}</span> </v-col>
                                <v-col> <span class="subtitle-1 font-weight-bold">{{ data.email }}</span> </v-col>
                            </v-row>
                            <hr style="border: 1px solid #E0E0E0;margin-top: -10px">
                            <v-row>
                                <v-col :md="4"> <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.phone_number') }}</span> </v-col>
                                <v-col> <span class="subtitle-1 font-weight-bold">{{ data.mobile }}</span> </v-col>
                            </v-row>
                            <hr style="border: 1px solid #E0E0E0;margin-top: -10px">
                            <v-row>
                                <v-col :md="4"> <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.total_activity') }}</span> </v-col>
                                <v-col> <span class="subtitle-1 font-weight-bold">{{ data.total_activity }}</span> </v-col>
                            </v-row>
                            <hr style="border: 1px solid #E0E0E0;margin-top: -10px">
                            <v-row>
                                <v-col :md="4"> <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.joined_date') }}</span> </v-col>
                                <v-col> <span class="subtitle-1 font-weight-bold">{{ data.fjoined_date }}</span> </v-col>
                            </v-row>
                            <hr style="border: 1px solid #E0E0E0; margin-top: -10px">
                        </v-col>
                    </v-row>
                <p class="font-weight-bold title ml-9 mt-10"> {{ lang.t('$vuetify.activity') }} </p>
                <div v-if="dataActivity.length > 0" class="px-4 mb-4">
                    <v-card v-for="(item, idx) in dataActivity" :key="idx" raised class="pa-6 mb-4" style="border-radius: 8px;">
                        <div>
                            <span class="subtitle-1" style="color: #00D79E">{{ item.last_activity }}</span>
                            <v-icon color="#BEC2C4">mdi-circle-medium</v-icon>
                            <span class="subtitle-1 font-weight-bold">{{ item.fcreated_at }}</span>
                        </div>
                        <div>
                            <span class="subtitle-1" style="color: #6A6A6A">{{ item.subject }}</span>
                        </div>
                    </v-card>
                </div>
                <div v-else class="px-4 mb-4">
                    <v-card class="pa-6 mb-4" style="border-radius: 8px;">
                        <div>
                            <span class="subtitle-1" style="color: #6A6A6A">{{ lang.t('$vuetify.no_activity') }}</span>
                        </div>
                    </v-card>
                </div>
                <div v-if="page !== 0">
                  <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    class="mb-5"
                    @input="changePage"
                    ></v-pagination>
                </div>
            </v-card>
            <div>
                <span class="subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.created_by') }}</span>
                <div>
                    <v-avatar size="28" class="mr-3">
                        <v-img src="@/assets/Screenshot(4).png"></v-img>
                    </v-avatar>
                    <span class="subtitle-1 font-weight-bold">{{ data.created_by }}</span>
                    <span class="subtitle-1" style="color: #BEC2C4"> at </span>
                    <span class="subtitle-1 font-weight-bold">15 Feb 2020, 13:30</span>
                </div>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="664">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.edit_admin') }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                  <div class="ml-2">
                    <v-row>
                    <v-col :md="6" >
                        <div class="justify-space-between">
                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.name') }}</span>
                            </div>
                            <v-text-field v-model="data.name" outlined dense></v-text-field>
                            <!-- <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.username') }}</span>
                            </div>
                            <v-text-field v-model="data.username" outlined dense></v-text-field> -->
                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.email') }}</span>
                            </div>
                            <v-text-field v-model="data.email" outlined dense :rules="[rules.required, rules.email]"></v-text-field>
                            <!-- <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span class="mt-4">Password</span>
                            </div>
                            <v-text-field v-model="data.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" outlined dense></v-text-field> -->
                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.phone_number') }}</span>
                            </div>
                            <v-text-field v-model="data.mobile" outlined dense></v-text-field>
                        </div>
                    </v-col>
                    <v-col :md="6" >
                        <div class="justify-space-between">
                            <div class="d-flex flex-no-wrap justify-space-between" >
                                <span class="mt-1 mb-2">{{ lang.t('$vuetify.admin') }} {{ lang.t('$vuetify.level') }}</span>
                            </div>
                            <v-autocomplete v-model="data.role_id" :items="listAdminRole" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.country') }}</span>
                            </div>
                            <v-autocomplete v-model="data.country_id" :items="listCountry" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                            <div class="d-flex flex-no-wrap justify-space-between mb-3">
                                <span>{{ lang.t('$vuetify.city') }}</span>
                            </div>
                            <v-autocomplete v-model="data.city_id" :items="listCity" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                            <div class="d-flex flex-no-wrap justify-space-between mt-5">
                                <span class="mt-5 subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.upload') }}</span>
                                <span class="mt-6 caption" style="color: #BEC2C4">png or jpg, max 300 kb</span>
                            </div>
                            <v-file-input :rules="rulesUpload" accept="image/png, image/jpeg, image/bmp" placeholder="Pick an avatar" prepend-icon="mdi-camera" style="border-color: red"></v-file-input>
                        </div>
                    </v-col>
                    </v-row>
                      <div class="text-center">
                        <v-btn width="552px" rounded class="text-capitalize" color="#00D79E" style="color: #FFFFFF" @click="updateDataAdmin">{{ lang.t('$vuetify.update') }}</v-btn>
                      </div>
                    </div>
                </div>
              </v-card>
            </v-dialog>
            <v-snackbar v-model="notifyUpdate" :timeout="timeoutNotify" multi-line top right :color="colorNotify" >
              {{ textNotify }}
              <v-btn color="white" text @click="notifyUpdate = false">
                {{ lang.t('$vuetify.close') }}
              </v-btn>
            </v-snackbar>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import { detailAdmin, updateAdmin, deleteAdmin, adminActivity } from '@/api/admin'
import { listCountry, listCity, listAdminRole } from '@/api/global'

export default {
  name: 'DetailAdmin',
  components: {},
  data () {
    return {
      page: 0,
      total_page: 0,
      data: {
        name: ''
      },
      dataActivity: {},
      dialog: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      rulesUpload: [
        value => !value || value.size < 300000 || 'Image size should be less than 300 kb!'
      ],
      listCountry: [],
      listCity: [],
      listAdminRole: [],
      notifyUpdate: false,
      textNotify: '',
      timeoutNotify: 2000,
      colorNotify: ''
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  mounted () {
    this.getData()
    this.getActivity(1)
    this.getRole()
    this.getCountry()
    this.getCity()
  },

  methods: {
    getData () {
      // this.listLoading = true
      detailAdmin(this.$route.params.id).then((response) => {
        this.data = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getActivity (item) {
      // this.listLoading = true
      var data = {
        id: this.$route.params.id,
        param: {
          page: item,
          per_page: 10,
          sort_by: 'created_at',
          sort: 'desc'
        }
      }
      adminActivity(data).then((response) => {
        this.dataActivity = response.data.data
        this.page = response.data.meta.current_page
        this.total_page = response.data.meta.last_page
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getCountry () {
      // this.listLoading = true
      var param = {
        per_page: 300
      }
      listCountry(param).then((response) => {
        this.listCountry = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getCity () {
      // this.listLoading = true
      var param = {
        per_page: 500
      }
      listCity(param).then((response) => {
        this.listCity = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getRole () {
      // this.listLoading = true
      var param = {
        per_page: 300
      }
      listAdminRole(param).then((response) => {
        this.listAdminRole = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    updateDataAdmin () {
      // this.listLoading = true
      var data = {
        id: this.data.id,
        name: this.data.name,
        mobile: this.data.mobile,
        role_id: this.data.role_id,
        country_id: this.data.country_id,
        city_id: this.data.city_id
      }
      updateAdmin(data).then((response) => {
        this.data = response.data.data
        this.getActivity(1)
        console.log('respon')
        this.dialog = false
      }).catch((error) => {
        console.log('rsp', error)
        this.textNotify = error
        this.notifyUpdate = true
        this.colorNotify = 'error'
      })
      // this.listLoading = false
    },

    delAction () {
      confirm(this.lang.t('$vuetify.are_you_sure_delete') + this.data.name + ' ?') && this.delDataAdmin()
    },

    delDataAdmin () {
      // this.listLoading = true
      deleteAdmin(this.data.id).then((response) => {
        this.textNotify = response.data.data.message
        this.notifyUpdate = true
        this.colorNotify = 'succes'
        this.$router.push('/admin')
      }).catch((error) => {
        console.log('rsp', error)
        this.textNotify = error
        this.notifyUpdate = true
        this.colorNotify = 'error'
      })
      // this.listLoading = false
    },

    changePage (item) {
      this.getActivity(item)
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
